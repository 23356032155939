import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/store";
import {
  userApi,
  streamingLinkApi,
  streamingLinkApiHeaderTransform,
  tenantHeaderApi,
  tenantHeaderTransform
} from "api/api";
import * as ApiConstants from "constants/apiConstants";
import { getTenantList } from "_pages/tenants/tenantSlice";
import { postImages } from "_pages/channels/channelSlice";
import {
  getCustomAPIErrorMessage,
  getIntensityEnums,
  getResultParams,
  getSkillLableEnums,
  getSortOptions,
  handleUnauthorized,
} from "utils/utilityFunctions";
import {
  regfeshTokenId,
  refreshTokenWithoutCallback,
} from "_pages/loginscreen/authSlice";
import { getScheduleEvents } from "_pages/scheduleEvents/ScheduleEventSlice";
import {
  apiResponseEnums,
  NETWORK_STATUS_ENUMS,
  paginationValues,
  VIDEO_STATUS_ENUM,
  userRoleEnums,
  VIDEO_STATUS_REUPLOAD,
  MEDIA_NOT_MATCHED_VIDEO_NOT_EXIST,
  USER_MESSAGES,
  DOWNLOAD_FILE_NAMES,
  THUMBNAIL_TYPE
} from "constants/enums";
import { createNewTitle, createNewTitleFromRowFile } from "./Components/utils";

import { addTitlesToQueue, getQueuedTitles, deletTitlesQueue } from "utils/db";

import { createResumableInstance } from "utils/uploadUtilFuctions";
import {
  orderBy,
  isEmpty,
  find,
  findIndex,
  filter,
  includes,
  map,
} from "lodash";
import {
  contentTitleListCall,
  getContentList,
  getContent,
  cancelContentTitle,
} from "./ContentAction";
import { CONTENT_STATUS_ENUM } from "../../constants/enums";
import moment from "moment";
import { batch } from "react-redux";
import { contentUpload } from "./ContentUpload";
import { setApiResponse } from "Layout/layoutSlice";
import { AutoCoverImagePayloadType, ErrorObjectType } from "models/common.model";
import { contentTitlesEdit } from "constants/routeConstants";
import { CommonAPIResponse } from "models/common.model";
interface contentTitleState {
  contentTitlesData: any;
  metaData: any;
  language: any;
  error: string;
  publishingError: string;
  isChannelCreated: boolean;
  isChannelPublished: boolean;
  isFileUploaded: boolean;
  fileUrl: string;
  contentStatus: number;
  status: number;
  firstTitleBeingUploaded: any;
  secondTitleBeingUploaded: any;
  thirdTitleBeingUploaded: any;
  TileBeingUploadedStatus: any;
  selectedContentTitleRows: any;
  TitlesBeingUploaded: any;
  concurrentUploadNumber: number;
  TrailerUploadProgress: any;
  replaceVideoUploadStatus: any;
  currentTagId: any;
  uploadTags: Array<any>;
  ErrorInUploads: any;
  networkStatus: number;
  areUploadsGoingOnInOtherTab: boolean;
  isUploadGoingOn: boolean;
  showOnlyUploadButton: number;
  ErrorInTrailerUploads: any;
  isBrokenFileBeingUploaded: any;
  isMetaDataFormUpdated: boolean;
  blockNewUploads: any;
  filteredContentTitles: any;
  enteredQuery: string;
  isLoading: boolean;
  streamingLink: any;
  errorInTitleStreamingLink: string;
  streamingLinkLoader: boolean;
  captionsCreateLoader: boolean;
  captionsSaveSuccess: boolean;
  loadingFile: boolean;
  autoGeneratedCoverImage: string;
  autoGeneratedCoverImageLoading: boolean;
}

const initialState: contentTitleState = {
  contentTitlesData: {},
  selectedContentTitleRows: {
    isError: false,
    msg: "",
    loading: false,
    list: [],
    selectedTag: [],
    deleteLoading: false,
    isTrailer: null,
    updateLoading: false,
    selectedUploadingTag: [],
    isTrailerUpdating: { loading: false, list: [] },
    isVideoUpdating: { loading: false, list: [] },
    isReupload: false,
    reuploadTags: [],
  },
  currentTagId: null,
  uploadTags: [],
  TitlesBeingUploaded: [],
  metaData: {},
  language: {},
  isLoading: false,
  isChannelCreated: false,
  isChannelPublished: false,
  isFileUploaded: false,
  error: "",
  fileUrl: "",
  contentStatus: 0,
  status: 0,
  publishingError: "",
  firstTitleBeingUploaded: {},
  secondTitleBeingUploaded: {},
  thirdTitleBeingUploaded: {},
  replaceVideoUploadStatus: [],
  TileBeingUploadedStatus: [0, 0, 0, 0, 0],
  TrailerUploadProgress: {},
  concurrentUploadNumber: 0,
  ErrorInUploads: null,
  networkStatus: NETWORK_STATUS_ENUMS.UNKNOWN,
  areUploadsGoingOnInOtherTab: false,
  isUploadGoingOn: false,
  showOnlyUploadButton: 0,
  ErrorInTrailerUploads: {},
  isBrokenFileBeingUploaded: { status: false, message: "" },
  isMetaDataFormUpdated: false,
  blockNewUploads: { status: false, message: "" },
  filteredContentTitles: {},
  enteredQuery: "",
  streamingLink: {},
  errorInTitleStreamingLink: "",
  streamingLinkLoader: true,
  captionsCreateLoader: false,
  captionsSaveSuccess: false,
  loadingFile: false,
  autoGeneratedCoverImage: "",
  autoGeneratedCoverImageLoading: false,
};

export const contentTitleSlice = createSlice({
  name: "provider",
  initialState,
  reducers: {
    getContentTitles: (state, action: PayloadAction<any>) => {
      state.contentTitlesData = action.payload;
    },
    resetContentTitles: (state, action: PayloadAction<any>) => {
      state.contentTitlesData = initialState.contentTitlesData;
    },
    setContentLoading: (state, action: PayloadAction<any>) => {
      state.isLoading = action.payload;
    },
    setCurrentTagId: (state, action: PayloadAction<any>) => {
      state.currentTagId = action.payload;
      state.uploadTags = [action.payload, ...state.uploadTags];
    },
    setUploadTagId: (state, action: PayloadAction<any>) => {
      state.uploadTags = filter(
        state.uploadTags,
        (_) => _.titleTag !== action.payload
      );
    },
    clearTagId: (state) => {
      state.currentTagId = null;
      state.uploadTags = [];
    },
    setContentTitleRow: (state, action: PayloadAction<any>) => {
      state.selectedContentTitleRows.list = action.payload;
    },
    setContentTitleSelectedRowReuploadStatus: (state) => {
      state.selectedContentTitleRows.list[0].trailerStatus =
        VIDEO_STATUS_ENUM.UPLOADING;
    },
    setContentTitleRowEmpty: (state, action: PayloadAction<any>) => {
      state.selectedContentTitleRows.isError = action.payload.isError;
      state.selectedContentTitleRows.msg = action.payload.msg;
    },
    setContentTitleRowLoading: (state, action: PayloadAction<any>) => {
      state.selectedContentTitleRows.loading = action.payload;
    },
    setContentTitleTrailerUpdating: (state, action: PayloadAction<any>) => {
      state.selectedContentTitleRows.isTrailerUpdating.loading = action.payload;
    },
    setReuploadVideo: (state, action: PayloadAction<any>) => {
      state.selectedContentTitleRows.isReupload = action.payload;
    },
    setReuploadTags: (state, action: PayloadAction<any>) => {
      state.selectedContentTitleRows.reuploadTags = action.payload;
    },
    setContentTitleVideoUpdating: (state, action: PayloadAction<any>) => {
      state.selectedContentTitleRows.isVideoUpdating.loading = action.payload;
    },
    clearDeletedSelectedRow: (state) => {
      state.selectedContentTitleRows.selectedTag = [];
      state.selectedContentTitleRows.deleteLoading = false;
      state.selectedContentTitleRows.isTrailer = null;
    },
    setContentTitleDeleteRowLoading: (state, action: PayloadAction<any>) => {
      state.selectedContentTitleRows.deleteLoading = action.payload.status;
      state.selectedContentTitleRows.isTrailer = action.payload.isTrailer;
      if (
        action.payload.status &&
        !includes(
          state.selectedContentTitleRows.selectedTag,
          action.payload.titleTag
        )
      )
        state.selectedContentTitleRows.selectedTag = [
          action.payload.titleTag,
          ...state.selectedContentTitleRows.selectedTag,
        ];
      else {
        state.selectedContentTitleRows.selectedTag = filter(
          state.selectedContentTitleRows.selectedTag,
          (_) => _ !== action.payload.titleTag
        );
      }
    },
    clearUploadSelectedRow: (state) => {
      state.selectedContentTitleRows.selectedUploadingTag = [];
      state.selectedContentTitleRows.updateLoading = false;
    },
    setContentTitleUploadRowLoading: (state, action: PayloadAction<any>) => {
      state.selectedContentTitleRows.updateLoading =
        action.payload.updateLoading;
      if (
        !includes(
          state.selectedContentTitleRows.selectedUploadingTag,
          action.payload.titleTag
        )
      ) {
        state.selectedContentTitleRows.selectedUploadingTag = [
          action.payload.titleTag,
          ...state.selectedContentTitleRows.selectedUploadingTag,
        ];
        state.selectedContentTitleRows.updateLoading = true;
      } else {
        state.selectedContentTitleRows.selectedUploadingTag = filter(
          state.selectedContentTitleRows.selectedUploadingTag,
          (_) => _ !== action.payload.titleTag
        );
        state.selectedContentTitleRows.updateLoading =
          state.selectedContentTitleRows.selectedUploadingTag.length;
      }
    },
    setEnteredSearchQuery: (state, action: PayloadAction<any>) => {
      state.enteredQuery = action.payload;
    },
    getFilteredContentTitles: (state, action: PayloadAction<any>) => {
      state.filteredContentTitles = action.payload;
    },
    setBrokenFileBeingUploaded: (state, action: PayloadAction<any>) => {
      state.isBrokenFileBeingUploaded = action.payload;
    },
    setNewFileBeingUploaded: (state, action: PayloadAction<any>) => {
      state.blockNewUploads = action.payload;
    },
    getContentTitlesBeingUploaded: (state, action: PayloadAction<any>) => {
      state.TitlesBeingUploaded = action.payload;
    },
    setReplaceVideoStatus: (state, action: PayloadAction<any>) => {
      state.replaceVideoUploadStatus = action.payload;
    },
    getMetaData: (state, action: PayloadAction<any>) => {
      state.metaData = action.payload;
    },
    getLanguage: (state, action: PayloadAction<any>) => {
      state.language = action.payload;
    },
    channelCreated: (state, action: PayloadAction<any>) => {
      state.isChannelCreated = action.payload;
    },
    channelPubling: (state, action: PayloadAction<any>) => {
      state.isChannelPublished = action.payload;
    },
    errorInChennelCreation: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    errorInContentPublishing: (state, action: PayloadAction<any>) => {
      state.publishingError = action.payload;
    },
    fileUploaded: (state, action: PayloadAction<any>) => {
      state.isFileUploaded = action.payload.status;
      state.fileUrl = action.payload.url;
    },
    updateContentStatus: (state, action: PayloadAction<any>) => {
      state.contentStatus = action.payload;
      state.status = action.payload.status;
    },
    
    setUploadNumber: (state, action: PayloadAction<any>) => {
      state.concurrentUploadNumber = action.payload.uploadNumber;
    },
    setTrailerProgess: (state, action: PayloadAction<any>) => {
      state.TrailerUploadProgress = action.payload;
    },
    setErrorsInUploads: (state, action: PayloadAction<any>) => {
      state.ErrorInUploads = action.payload;
    },
    changeNetworkStatus: (state, action: PayloadAction<any>) => {
      state.networkStatus = action.payload;
    },
    setUploadStatusInAnotherTab: (state, action: PayloadAction<any>) => {
      state.areUploadsGoingOnInOtherTab = action.payload;
    },
    setUploadStatusToTrue: (state, action: PayloadAction<any>) => {
      state.isUploadGoingOn = action.payload;
    },
    setUploaodButtonStatus: (state, action: PayloadAction<any>) => {
      state.showOnlyUploadButton = action.payload;
    },
    setErrorInTrailorUploads: (state, action: PayloadAction<any>) => {
      state.ErrorInTrailerUploads = action.payload;
    },
    setVideoMetaDataFormStatus: (state, action: PayloadAction<any>) => {
      state.isMetaDataFormUpdated = action.payload;
    },
    setTitleStreamingLinkLoading: (state, action: PayloadAction<any>) => {
      state.streamingLinkLoader = action.payload;
    },
    setTitleStreamingLink: (state, action: PayloadAction<any>) => {
      state.streamingLink = action.payload;
    },
    errorInGetTitleStreamingLink: (state, action: PayloadAction<any>) => {
      state.errorInTitleStreamingLink = action.payload;
    },
    setCaptionsCreateLoading: (state, action: PayloadAction<any>) => {
      state.captionsCreateLoader = action.payload;
    },
    setCaptionsSaveSuccess: (state, action: PayloadAction<any>) => {
      state.captionsSaveSuccess = action.payload;
    },
    setLoadingFile: (state, action: PayloadAction<boolean>) => {
      state.loadingFile = action.payload as boolean;
    },
    setAutoGeneratedThumbnailLoader:(state, action: PayloadAction<boolean>) => {
      state.autoGeneratedCoverImageLoading = action.payload as boolean;
    },
    setAutoGeneratedThumbnail:(state, action: PayloadAction<string>) => {
      state.autoGeneratedCoverImage = action.payload;
    },
  },
});

export const {
  setErrorInTrailorUploads,
  setUploaodButtonStatus,
  setUploadStatusToTrue,
  setUploadStatusInAnotherTab,
  setErrorsInUploads,
  changeNetworkStatus,
  setUploadNumber,
  setTrailerProgess,
  getContentTitlesBeingUploaded,
  getContentTitles,
  setContentLoading,
  setCurrentTagId,
  setUploadTagId,
  clearTagId,
  setContentTitleRow,
  setContentTitleSelectedRowReuploadStatus,
  setContentTitleRowEmpty,
  setContentTitleRowLoading,
  clearDeletedSelectedRow,
  setContentTitleTrailerUpdating,
  setContentTitleVideoUpdating,
  setReuploadVideo,
  setReuploadTags,
  setContentTitleDeleteRowLoading,
  clearUploadSelectedRow,
  setContentTitleUploadRowLoading,
  errorInContentPublishing,
  updateContentStatus,
  getMetaData,
  getLanguage,
  channelCreated,
  errorInChennelCreation,
  fileUploaded,
  channelPubling,
  setReplaceVideoStatus,
  setBrokenFileBeingUploaded,
  setVideoMetaDataFormStatus,
  setNewFileBeingUploaded,
  getFilteredContentTitles,
  setEnteredSearchQuery,
  setTitleStreamingLink,
  errorInGetTitleStreamingLink,
  setTitleStreamingLinkLoading,
  setCaptionsCreateLoading,
  resetContentTitles,
  setCaptionsSaveSuccess,
  setLoadingFile,
  setAutoGeneratedThumbnailLoader,
  setAutoGeneratedThumbnail
} = contentTitleSlice.actions;

export const resetReduxContentTitles = (): AppThunk => (dispatch) => {
         // RR || The function below is called to check user auth status from firebase
     dispatch(resetContentTitles({}));
 };
export const updateContentTilte = (
  data: any,
  lastTableConfig: any
): AppThunk => (dispatch,getState) => {
   const {
     contentTitles: { autoGeneratedCoverImage: autoCoverImage },
   } = getState();

  dispatch(updateContentStatus({ status: 0 }));
  let body = {
    alternateLink: data.alternateLink,
    channelId: data.channel,
    classCategory: data.category,
    classDescription: data.description,
    classLanguage: data.videoLanguage,
    classLanguageCode: data.classLanguageCode,
    className: data.name,
    classSubCategory: data.subCategory,
    creationDate: data?.creationDate || moment().format(),
    durationSecond: data.durationSecond,
    endDate: data.endDate || null,
    equipments: data.equipments,
    equipmentTypeTags: data.equipmentTypeTags,
    errorType: 0,
    focusArea: data.focusAreas,
    focusAreaTags: data.focusAreaTags,
    imageLink:
      data?.thumbnailType === THUMBNAIL_TYPE.AUTO ? autoCoverImage : data.cover,
    instructor: data.instructorName,
    intensity: getIntensityEnums(data.intensity),
    isActive: data.isActive,
    isEquipment: data.isEquipment,
    jobID: null,
    keywords: null,
    labels: data.labels,
    level: data.level,
    provider: data.provider,
    providerID: data.providerId,
    scheduleDate: data.releaseDate,
    skill: getSkillLableEnums(data.skillLevel),
    startDate: data.startDate,
    status: data.status,
    streamingLink: data.video,
    tag: data.tag,
    trailerLinkMobile: data.trailerMobile,
    trailerLinkWeb: data.trailerWeb,
    trailerName: data.trailerName,
    IsMigrating: data.IsMigrating,
    SourceProviderId: data.SourceProviderId,
    classCategoryId: data.classCategoryId ?? data.categoryId, // need to be changed
    IsAutoPublish: data?.IsAutoPublish ?? false,
    classSubCategoryId: data?.classSubCategoryId,
    thumbnailType: data?.thumbnailType ?? THUMBNAIL_TYPE.AUTO,
  };
  userApi
    .put(`${ApiConstants.updateContentTitlesAPI}/${data.tag}`, {
      ...body,
    })
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(setVideoMetaDataFormStatus(true));
        dispatch(channelCreated(true));
        setTimeout(function() {
          dispatch(channelCreated(false));
          dispatch(setVideoMetaDataFormStatus(false));
        }, 1500);
        dispatch(
          setApiResponse({ status: res.status, data: USER_MESSAGES.SAVED })
        );
        dispatch(updateContentStatus({ status: res.data.status }));
      } else {
        dispatch(errorInChennelCreation("Please Check You Data"));
        setTimeout(function() {
          dispatch(errorInChennelCreation(""));
        }, 1500);
        const callback = () =>
          dispatch(regfeshTokenId(updateContentTilte(data, lastTableConfig)));
        handleUnauthorized(res.status, callback);
        dispatch(
          setApiResponse({
            status: res.status ?? apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage(res),
          })
        );
      }
    });
};

export const uploadFile = (data: any, type: string): AppThunk => (dispatch) => {
  const res = postImages(data.img, type);

  dispatch(fileUploaded({ fileUrl: res, status: true }));
};

export const getLanguageFromAPI = (): AppThunk => (dispatch) => {
  userApi.get(ApiConstants.getLanguageAPI).then((res: any) => {
    if (res.status === apiResponseEnums.OKAY) {
      let data: any = res.data;
      dispatch(
        getLanguage({ language: orderBy(data, ["displayName"], "desc") })
      );
    } else if (res.status === apiResponseEnums.UNAUTHRISED) {
      dispatch(regfeshTokenId(getLanguageFromAPI()));
    } else if (res.status === apiResponseEnums.INTERNAL_ERROR) {
      dispatch(getLanguageFromAPI());
    } else {
      dispatch(getLanguage({ language: [] }));
    }
  });
};

const isLoading = (dispatch, isTrailer, loading = true) => {
  if (isTrailer === null) dispatch(setContentTitleRowLoading(loading));
  if (isTrailer === true) dispatch(setContentTitleTrailerUpdating(loading));
  if (isTrailer === false) dispatch(setContentTitleVideoUpdating(loading));
};
export const getContentTitle = (
  tag: string,
  isTrailer: any = null,
  loading?: any,
  tenantId = undefined
): AppThunk => (dispatch, getState) => {
  const {
    contentTitles: { selectedContentTitleRows: _ },
  } = getState();
  if (_.loading) return false;
  isLoading(dispatch, isTrailer, loading);
  getContent(tag, tenantId)
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        const records = res?.data?.data ?? [];
        if (records.length) {
          _?.isError &&
            dispatch(setContentTitleRowEmpty({ isError: false, msg: "" }));
        } else {
          // FOR HANDLING ERROR OR PREVENT API NOT RESPONDING
          return false;
        }
        dispatch(updateContentStatus({ status: records[0].status }));
        dispatch(setContentTitleRow(records));
        // rawVideoFileUrl FOR check whether video is replaced or not
        if (
          !isEmpty(records[0]?.rawVideoFileUrl) ||
          records[0]?.videoStatus === VIDEO_STATUS_ENUM.COMPLETED
        ) {
          const { providerID, tag } = records[0];

          const {
            auth: {
              user: { role },
            },
            provider: { tenantAsProviderData, providerData },
          } = getState();
          let curTenantId = tenantId;
          if (isEmpty(tenantId)) {
            if (role === userRoleEnums.TENANT_AS_PROVIDER_USER)
              curTenantId =
                tenantAsProviderData?.tenantAsProviders[0]?.tenantId;
            else {
              const providerInfo = find(providerData.providers, {
                tag: providerID,
              });
              curTenantId = providerInfo?.tenantId;
            }
          }
          dispatch(getTitleStreamingLinkfromApi(providerID, curTenantId, tag));
        }
      } else if (res.status === apiResponseEnums.UNAUTHRISED) {
        dispatch(
          regfeshTokenId(getContentTitle(tag, isTrailer, tenantId, loading))
        );
      }
    })
    .finally(() => {
      isLoading(dispatch, isTrailer, false);
    });
};
// tag,false,fileToUpload,event,titlesInUpload
export const deleteContentTitleForMetaData = (
  titleTag: string = "",
  isTrailerFile: boolean = false,
  tenantId: any = undefined
): AppThunk => (dispatch, getStateInfo) => {
  const {
    contentTitles: { selectedContentTitleRows, replaceVideoUploadStatus },
  } = getStateInfo();
  dispatch(
    setContentTitleDeleteRowLoading({
      status: true,
      titleTag,
      isTrailer: isTrailerFile,
    })
  );
  let removeTitleResponse;
  let dispatchCancelOrRemove = false;
  if (
    titleTag === selectedContentTitleRows.list[0].tag &&
    selectedContentTitleRows.list[0].trailerStatus ===
      VIDEO_STATUS_ENUM.COMPLETED && isTrailerFile
  ) {
    removeTitleResponse = removeTrailer(titleTag);
    dispatchCancelOrRemove = true;
  } else {
    removeTitleResponse = cancelContentTitle({ titleTag, isTrailerFile });
  }
  removeTitleResponse
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        if (dispatchCancelOrRemove)
         dispatch(
          setApiResponse({ status: res.status, data: USER_MESSAGES.TRAILER_REMOVE })
         );
        else {
          let tempReplacingVideos = [...replaceVideoUploadStatus];
          tempReplacingVideos = tempReplacingVideos.filter(i => i.tag !== titleTag);
          dispatch(setReplaceVideoStatus(tempReplacingVideos));
           dispatch(
             setApiResponse({
               status: res.status,
               data: USER_MESSAGES.FILE_CANCEL,
             })
           );
        }
        dispatch(getContentTitle(titleTag, isTrailerFile, false, tenantId)); //
      }
      if (res.status === apiResponseEnums.UNAUTHRISED) {
        dispatch(
          regfeshTokenId(
            deleteContentTitleForMetaData(titleTag, isTrailerFile, tenantId)
          )
        );
      }

    })
    .catch((e) => {
   })
    .finally(() => {
      setTimeout(function() {
        batch(() => {
          dispatch(
            setContentTitleDeleteRowLoading({
              status: false,
              titleTag,
              isTrailer: null,
            })
          );
        });
      }, 1500);
    });
};
export const deleteContentTitle = (
  titleTag: string = "",
  isTrailerFile: boolean = false,
  fileToUpload,
  event,
  titlesInUpload,
  filterData: any = {}
): AppThunk => (dispatch, getState) => {
  dispatch(
    setContentTitleDeleteRowLoading({
      status: true,
      titleTag,
      isTrailer: isTrailerFile,
    })
  );
  cancelContentTitle({ titleTag, isTrailerFile })
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        const isUserOnEditScreen = window.location.pathname.includes(
          contentTitlesEdit
        );
        batch(() => {
          dispatch(
            setApiResponse({
              status: res.status,
              data: USER_MESSAGES.FILE_CANCEL,
            })
          );
          dispatch(
            filterContentListfromApi(
              paginationValues.DAFAULT_PAGE_NUMBER,
              paginationValues.DEFAULT_PAGE_SIZE_FOR_CONTENT,
              "",
              "",
              null,
              true,
              localStorage.getItem("csTenantId") ?? "",
              isUserOnEditScreen ? titleTag : "",
              isUserOnEditScreen ? false : true
            )
          );
        });
      }
      if (res.status === apiResponseEnums.UNAUTHRISED) {
        dispatch(
          regfeshTokenId(
            deleteContentTitle(
              titleTag,
              isTrailerFile,
              fileToUpload,
              event,
              titlesInUpload
            )
          )
        );
      }
    })
    .catch((e) => {
    })
    .finally(() => {
      setTimeout(function() {
        batch(() => {
          dispatch(
            setContentTitleDeleteRowLoading({
              status: false,
              titleTag,
              isTrailer: null,
            })
          );
         
        });
      }, 1500);
    });
};
const sortMetaData = (_: any) => {
  try {
    return {
      ..._,
      categories: orderBy(_?.categories, ["category"], "asc"),
      channels: orderBy(_?.channels, ["displayName"], "asc"),
      equipments: orderBy(_?.equipments, ["name"], "desc"),
      focusAreas: orderBy(_?.focusAreas, ["name"], "desc"),
      languages: orderBy(_?.languages, ["displayName"], "asc"),
      providers: orderBy(_?.providers, ["displayName"], "asc"),
    };
  } catch (error) {
    return _;
  }
};
  const getTenantId = (role) => {
    let curTenantId;
    if (role === userRoleEnums.CS_USER) {
       curTenantId =
         localStorage.getItem("csTenantId") !== ""
           ? localStorage.getItem("csTenantId")
           : "";
    }
    return curTenantId;
  };
  
export const getmetaDataFromApi = (): AppThunk => (dispatch,getState) => {
   const {auth: {user: { role } } } = getState();
   let tenantId = getTenantId(role);
  
  if (role === userRoleEnums.CS_USER && !isEmpty(tenantId)) {
    tenantHeaderTransform(tenantId)
  }else{
    tenantHeaderTransform();
  }
  tenantHeaderApi
    .get(ApiConstants.getMetadataAPI)
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        let data: any = res.data;
        dispatch(getMetaData({ metaData: sortMetaData(data) }));
        dispatch(getTenantList({ tenants: data.tenants }));
      } else if (res.status === apiResponseEnums.OKAY) {
        dispatch(regfeshTokenId(getmetaDataFromApi));
      } else if (res.status === apiResponseEnums.INTERNAL_ERROR) {
        dispatch(getmetaDataFromApi());
      }  else if (res.status === apiResponseEnums.BAD_REQUEST) {
         dispatch(getMetaData({ metaData: [] }));
      }else {
        dispatch(getMetaData({ metaData: [] }));
      }
    });
};

export const getFilteredTitles = (
  data: any,
  page: number,
  items: number,
  tenantId,
  filterParamChannel: any,
  filterParamProvider: any,
  providerId: any,
  filterData?: any,
  csUser?: boolean,
  isCSTenantId?: any
): AppThunk => (dispatch, getState) => {
  const {
    auth: {
      user: { role },
    },
  } = getState();
  let body;
  body = {
    query: data,
    ...filterData,
    providerId: filterData?.providerId,
    equipmentTags: filterData?.equipments,
  };
  if (role === userRoleEnums.PRVIDER_USER) {
    body.channelId = filterParamChannel;
  }
  if (csUser == true && isCSTenantId != "") {
    body.tenant = isCSTenantId;
  }
  if (page === undefined || page === null) {
    page = paginationValues.DEFAULT_PAGE_SIZE;
  }

  if (items === undefined || items === null) {
    items = paginationValues.DEFAULT_PAGE_SIZE_FOR_CONTENT;
  }
  if (page === undefined || page === null) {
    page = paginationValues.DEFAULT_PAGE_SIZE;
  }

  if (items === undefined || items === null) {
    items = paginationValues.DEFAULT_PAGE_SIZE_FOR_CONTENT;
  }
  dispatch(getContentTitles({ titles: null }));
  dispatch(errorInChennelCreation(""));
  userApi
    .put(`${ApiConstants.getFilteredTitlesAPI}?page=${page}&take=${items}`, {
      ...body,
    })
    // .then((res)=>modifyRes(res))
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        let data: any = res.data;
        dispatch(getContentTitles({ titles: data }));
      } else if (res.status === apiResponseEnums.UNAUTHRISED) {
        dispatch(
          regfeshTokenId(
            getFilteredTitles(
              data,
              page,
              items,
              tenantId,
              filterParamChannel,
              filterParamProvider,
              providerId,
              filterData,
              csUser,
              isCSTenantId
            )
          )
        );
      } else if (res.status === apiResponseEnums.INTERNAL_ERROR) {
        dispatch(errorInChennelCreation("Please Check You Data"));
        setTimeout(function() {
          dispatch(errorInChennelCreation(""));
        }, 1500);
      } else {
        dispatch(getContentTitles({ titles: [] }));
      }
    });
};

export const publishTitles = (data: any, lastTableConfig: any): AppThunk => (
  dispatch
) => {
  let body = {
    alternateLink: data.alternateLink,
    channelId: data.channel,
    classCategory: data.category,
    classDescription: data.description,
    classLanguage: data.videoLanguage,
    classLanguageCode: data.classLanguageCode,
    className: data.name,
    classSubCategory: data.subCategory,
    creationDate: data.creationDate,
    durationSecond: data.durationSecond,
    endDate: data.endDate,
    equipments: data.equipments,
    equipmentTypeTags: data.equipmentTypeTags,
    errorType: 0,
    focusArea: data.focusAreas,
    focusAreaTags: data.focusAreaTags,
    imageLink: data.cover,
    instructor: data.instructorName,
    intensity: getIntensityEnums(data.intensity),
    isActive: data.isActive,
    isEquipment: data.isEquipment,
    jobID: null,
    keywords: null,
    labels: data.labels,
    level: data.level,
    provider: data.provider,
    providerID: data.providerId,
    scheduleDate: data.releaseDate,
    skill: getSkillLableEnums(data.skillLevel),
    startDate: data.startDate,
    status: data.status,
    streamingLink: data.video,
    tag: data.tag,
    trailerLinkMobile: data.trailerMobile,
    trailerLinkWeb: data.trailerWeb,
    trailerName: data.trailerName,
    IsMigrating: data.IsMigrating,
    SourceProviderId: data.SourceProviderId,
    classCategoryId: data.classCategoryId ?? data.categoryId,
    classSubCategoryId: data?.classSubCategoryId,
    thumbnailType: data?.thumbnailType ?? THUMBNAIL_TYPE.AUTO,
    // IsAutoPublish: data?.IsAutoPublish ?? false,
  };

  userApi
    .put(`${ApiConstants.publishContentTitles}/${data.tag}`, {
      ...body,
    })
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.TITLE_PUBLISHD,
          })
        );
        dispatch(setVideoMetaDataFormStatus(true));
        setTimeout(function() {
          dispatch(setVideoMetaDataFormStatus(false));
        }, 2500);

        dispatch(updateContentStatus({ status: res.data.status }));
      } else if (res.status === apiResponseEnums.UNAUTHRISED) {
        dispatch(regfeshTokenId(publishTitles(data, lastTableConfig)));
      } else if (
        res.status === apiResponseEnums.CUSTOM_ERROR_AS_CHANNEL_IS_CLUB_ONLY
      ) {
        dispatch(errorInContentPublishing("Please Check You Data"));
        setTimeout(function() {
          dispatch(errorInContentPublishing(""));
        }, 1500);
      } else if (res.status === apiResponseEnums.BAD_REQUEST) {
        let message = res.data.message ?? "Please Check You Data";
        dispatch(errorInChennelCreation(message));
        setTimeout(function() {
          dispatch(errorInChennelCreation(""));
        }, 1500);
      } else if (res.status === apiResponseEnums.INTERNAL_ERROR) {
        dispatch(errorInChennelCreation("Please Check You Data"));
        setTimeout(function() {
          dispatch(errorInChennelCreation(""));
        }, 1500);
      } else {
        dispatch(errorInChennelCreation("Something went wrong"));
        setTimeout(function() {
          dispatch(errorInChennelCreation(""));
        }, 1500);
      }
    });
};

export const contentTitlesExport = (
): AppThunk => (dispatch,getState) => {
  const {
    collections: { classFilteredData: filterData },
    sortTable: sortData,
    auth: {
      user: { role },
    },
    layout: { contentChannelValue: channelValue },
  } = getState();
  let data = getResultParams(false, filterData, getSortOptions, sortData);
  
  if (role === userRoleEnums.CS_USER && localStorage.getItem("csTenantId")!== null) {
    data.tenant = localStorage.getItem("csTenantId");
  }
  if (role === userRoleEnums.PRVIDER_USER){
    if (channelValue) data.channelId = channelValue;
  } 
  dispatch(setLoadingFile(true));
 
  userApi.post(ApiConstants.contentTitlesExport, data).then((res: any) => {
   
    if (res.status === apiResponseEnums.OKAY) {
      const url = window.URL.createObjectURL(new Blob([res.data] as any));
      const link = document.createElement("a");
      link.href = url;
      const date = moment(new Date()).format("DDMMMYYYY");
      link.setAttribute(
        "download",
        `${DOWNLOAD_FILE_NAMES.TITLE_EXPORT}_${date}.csv`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      dispatch(
        setApiResponse({
          status: res.status,
          data: USER_MESSAGES.TITLE_EXPORT,
        } as CommonAPIResponse)
      );
      dispatch(setLoadingFile(false));
    } else {
      dispatch(
        setApiResponse({
          status: res.status ?? apiResponseEnums.BAD_REQUEST,
          data: getCustomAPIErrorMessage({
            status: res.status as number,
            data: res.data as ErrorObjectType,
          }),
        })
      );
      dispatch(setLoadingFile(false));
    }
  });
};

export const filterContentListfromApi = (
  page: number,
  items: number,
  provider: string,
  channel: string,
  titlesInUploadTable: any = null,
  isOptioanDataAvailable: boolean = false,
  tenantId: any = "",
  titleTag: string = "",
  showLoading: boolean = true
): AppThunk => (dispatch, getState) => {
  let paramTenant = tenantId;
  const {
    collections: { classFilteredData: filterData },
    layout: { contentChannelValue: channelValue },
    auth: {
      user: { role },
    },
    contentTitles: { metaData },
    sortTable: sortData,
  } = getState();
  const resultParams = getResultParams(
    false,
    filterData,
    getSortOptions,
    sortData
  );
  const paramsData: any = {
    channelId: channelValue,
    providerId: provider,
    ...resultParams,
  };
  if (role === userRoleEnums.TENANT_AS_PROVIDER_USER) {
    paramsData.tenant = "";
    paramTenant = "";
  }
  const isUserOnEditScreen = window.location.pathname.includes(
    "/media/contentTitles/edit/"
  );
  const iscurrentTitleTag = window.location.pathname.includes(titleTag);
  if (iscurrentTitleTag == false && isUserOnEditScreen) return false;
  if (isUserOnEditScreen && isEmpty(titleTag) === false && titleTag !== "") {
    dispatch(getContentTitle(titleTag, null, showLoading, paramTenant));
    return false;
  }
  if (titleTag) dispatch(setContentTitleVideoUpdating(true));
  else dispatch(setContentLoading(true));
  if (isOptioanDataAvailable === false) {
    dispatch(getContentTitles({ titles: null }));
    dispatch(updateContentStatus({ status: 0 }));
  }

  if (titleTag) {
    paramsData.titleTags = [titleTag];
    dispatch(setContentTitleRowEmpty({ isError: false, msg: "" }));
  }
  if (page === undefined || page === null) {
    page = paginationValues.DEFAULT_PAGE_SIZE;
  }
  if (items === undefined || items === null) {
    items = paginationValues.DEFAULT_PAGE_SIZE_FOR_CONTENT;
  }
  getContentList({
    page,
    take: items,
    paramTenant,
    paramsData,
  })
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        let data: any = res.data;
        // API IS NOT GIVING RESPONSE
        if (titleTag) {
          if (data.data.length) {
            dispatch(setContentTitleRowEmpty({ isError: false, msg: "" }));
          } else {
            dispatch(
              setContentTitleRowEmpty({
                isError: true,
                msg: "Record not found",
              })
            );
          }
        }
        if (data && data.data.length) {
          dispatch(getContentTitles({ titles: data }));

          if (
            isOptioanDataAvailable === true &&
            titlesInUploadTable !== null &&
            titlesInUploadTable !== ""
          ) {
            dispatch(getContentTitlesBeingUploaded(titlesInUploadTable));
          }
          if (isOptioanDataAvailable === false && isEmpty(metaData)) {
            dispatch(getmetaDataFromApi());
          }
        }
      } else if (res.status === apiResponseEnums.UNAUTHRISED) {
        dispatch(
          regfeshTokenId(
            filterContentListfromApi(
              page,
              items,
              provider,
              channel,
              titlesInUploadTable,
              isOptioanDataAvailable,
              paramTenant,
              titleTag,
              showLoading
            )
          )
        );
      } else {
        dispatch(getContentTitles({ titles: [] }));
      }
    })
    .finally(() => {
      if (titleTag) dispatch(setContentTitleVideoUpdating(false));
      else dispatch(setContentLoading(false));
    });
};

let ResumableInstance: any = [];
export const addBrokenTitlesToTable = (type): AppThunk => (dispatch) => {
  dispatch(resetUploadStatusforNewFiles());
};

export const refreshToken = (): AppThunk => (dispatch) => {
  dispatch(refreshTokenWithoutCallback());
};
export const removeTrailer = (id: any) => {
  return userApi.put(`${ApiConstants.removeTrailer}?Titletag=${id}`);
};
export const UploadRetryFile = (
  fileToUpload,
  event: any = null,
  titlesInUpload: any = null,
  tag: any = null
): AppThunk => (dispatch, getState) => {
  contentUpload(fileToUpload, event, tag, getState, dispatch);
};
const getInstance = (tag: any = false) => {
  let URI: string = ApiConstants.uploadTitleVideoAPI;
  URI = tag && URI + tag;
  return createResumableInstance(null, null, URI, false);
};
export const UploadCancelFile = (
  fileToUpload,
  event: any = null,
  titlesInUpload: any = null,
  tag: any = null,
  filterData: any = {}
): AppThunk => (dispatch, getState) => {
  if (find(titlesInUpload, { tag })) {
    if (ResumableInstance.length) ResumableInstance[tag].cancel();
    else {
      const curInstance = getInstance(tag);
      curInstance.cancel();
    }
  }
  dispatch(
    deleteContentTitle(
      tag,
      false,
      fileToUpload,
      event,
      titlesInUpload,
      filterData
    )
  );
};
export const uploadNewFileWithbroken = (
  fileToUpload,
  event: any = null,
  titlesInUpload: any = null,
  index?: number
): AppThunk => (dispatch) => {
  dispatch(
    resumableUploader(
      fileToUpload,
      event,
      false,
      null,
      false,
      null,
      index,
      titlesInUpload
    )
  );
};
export const deleteContentTitles = (
  payload: any,
  item,
  page,
  from = "",
  filterParamProvider = "",
  filterParamChannel = "",
  enteredSearchQuery = "",
  providerId = "",
  tenantId = "",
  resultParams = {}
): AppThunk => (dispatch) => {
  dispatch(getContentTitles({ titles: null }));

  if (providerId && providerId !== "") {
    tenantHeaderTransform(tenantId as any);
  }
  tenantHeaderApi
    .delete(
      ApiConstants.deletContentTitlesAPI,
      {},
      {
        data: payload,
      }
    )
    .then((res) => {
      if (res.status === apiResponseEnums.OKAY) {
        if (from == "CS_USER" && enteredSearchQuery?.length == 0) {
          dispatch(
            getFilteredTitles(
              "",
              item,
              page,
              tenantId,
              filterParamChannel,
              filterParamProvider,
              providerId,
              resultParams,
              true,
              tenantId
            )
          );
        } else if (from != "TasP" && enteredSearchQuery?.length == 0) {
          if (
            (filterParamChannel || filterParamProvider) &&
            (filterParamChannel?.length > 0 || filterParamProvider?.length > 0)
          ) {
            dispatch(
              getFilteredTitles(
                "",
                item,
                page,
                tenantId,
                filterParamChannel,
                filterParamProvider,
                providerId,
                resultParams,
                false,
                tenantId
              )
            );
          } else {
            dispatch(
              getFilteredTitles(
                "",
                item,
                page,
                tenantId,
                filterParamChannel,
                filterParamProvider,
                providerId,
                resultParams,
                false,
                tenantId
              )
            );
          }
        } else if (enteredSearchQuery?.length > 0) {
          if (from == "CS_USER") {
            dispatch(
              getFilteredTitles(
                "",
                item,
                page,
                tenantId,
                filterParamChannel,
                filterParamProvider,
                providerId,
                resultParams,
                true,
                tenantId
              )
            );
          } else if (from != "Tasp") {
            if (
              (filterParamChannel || filterParamProvider) &&
              (filterParamChannel?.length > 0 ||
                filterParamProvider?.length > 0)
            ) {
              setTimeout(() => {
                dispatch(
                  getFilteredTitles(
                    enteredSearchQuery,
                    item,
                    page,
                    tenantId,
                    filterParamChannel,
                    filterParamProvider,
                    providerId,
                    resultParams,
                    false,
                    tenantId
                  )
                );
              }, 3000);
            } else {
              setTimeout(() => {
                dispatch(
                  getFilteredTitles(
                    enteredSearchQuery,
                    item,
                    page,
                    tenantId,
                    "",
                    "",
                    providerId,
                    resultParams,
                    false,
                    tenantId
                  )
                );
              }, 3000);
            }
          }
        } else
          dispatch(
            filterContentListfromApi(
              item,
              page,
              filterParamProvider,
              "",
              null,
              false,
              tenantId,
              "",
              false
            )
          );
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.ITEM_DELETE,
          })
        );
      } else if (res.status === apiResponseEnums.UNAUTHRISED) {
        dispatch(
          regfeshTokenId(
            deleteContentTitles(
              payload,
              item,
              page,
              from,
              filterParamProvider,
              filterParamChannel,
              enteredSearchQuery,
              providerId,
              tenantId,
              resultParams
            )
          )
        );
      } else {
        if (from != "TasP")
          dispatch(
            filterContentListfromApi(
              item,
              page,
              "",
              "",
              null,
              false,
              tenantId,
              "",
              false
            )
          );
        else
          dispatch(
            filterContentListfromApi(
              item,
              page,
              filterParamProvider,
              "",
              null,
              false,
              tenantId,
              "",
              false
            )
          );
      }
    });
};
export const uploadBrokenFile = (
  brokenFileName,
  allFiles: any = null
): AppThunk => (dispatch) => {
  getQueuedTitles().then((file: any) => {
    let fileIndex = 0;
    for (let l = 0; l < file.length; l++) {
      if (file[l].name === brokenFileName) {
        fileIndex = l;
      }
    }

    let fileToUpload = [file[fileIndex].file];

    dispatch(resumableUploader(fileToUpload, undefined, true, allFiles));
  });
};

export const getBrokenUploads = (): AppThunk => (dispatch) => {
  getQueuedTitles().then((file: any) => {
    let fileList = [file[0].title];
    //console.log([file[0]]);
    dispatch(resumableUploader(fileList, undefined));
  });
};

export const resumableUploader = (
  File: any,
  Event: any,
  isFileBroken: boolean = false,
  brokenFilesList: any = null,
  isReplacing: boolean = false,
  tag: any = null,
  index: number = 0,
  filterData: any = {}
): AppThunk => (dispatch, getState) => {
  if (isFileBroken) {
    dispatch(
      setBrokenFileBeingUploaded({
        status: true,
        message: "You can upload only one broken file at once.",
      })
    );
  }

  // creating unique identifier
  let file = File[index];
  // if it is a broken upload, it will not create a new identifier, it will use old identifier
  let lastBrokenChunk = 0;
  if (isFileBroken === false) {
    const timeStamp = Date.now();
    file["identifier"] = `${timeStamp}-${file.name}`;
  }

  let interval;

  let URL = ApiConstants.uploadTitleVideoAPI;
  let singleResumableInstance;

  const {
    contentTitles: { selectedContentTitleRows: { reuploadTags } },
  } = getState();

  if (isReplacing && tag) {
    //console.log(tag, "tag");
    URL = ApiConstants.replaceTitleVideoAPI + tag;
    singleResumableInstance = createResumableInstance(
      File,
      Event,
      URL,
      isReplacing
    );
    dispatch(setReuploadVideo(true));
    const reUploadTagsTemp = [ ...reuploadTags ];
    reUploadTagsTemp.push(tag);
    dispatch(setReuploadTags(reUploadTagsTemp));
  } else {
    singleResumableInstance = createResumableInstance(
      null,
      null,
      ApiConstants.uploadTitleVideoAPI,
      false
    );
  }

  if (File) {
    let isFileAreadyInQueue = false;
    for (let i = 0; i < singleResumableInstance.files.length; i++) {
      if (singleResumableInstance.files[i] === File?.identifier) {
        isFileAreadyInQueue = true;
      }
    }

    if (isFileAreadyInQueue === false) {
      singleResumableInstance.appendFilesFromFileList(File, Event);
    } else {
      const status = new Promise((resolve) => {
        singleResumableInstance.removeFile(File);

        resolve("done");
      });
      status.then(() => {
        singleResumableInstance.appendFilesFromFileList(File, Event);
      });
    }
  }
  let items = [] as any;
  let titlesListInStore = [] as any;

  if (isReplacing === false) {
    if (isFileBroken === false) {
      addTitlesToQueue(file, createNewTitleFromRowFile(file), file.name);
    }
    if (brokenFilesList?.length > 1 && isFileBroken === true) {
      titlesListInStore = brokenFilesList.filter(
        (title) => title.className !== file.name
      );
    }
    if (isFileBroken === false && brokenFilesList?.length > 0) {
      titlesListInStore = brokenFilesList;
    }
    const {
      layout: { contentChannelData },
      contentTitles: { currentTagId, TitlesBeingUploaded },
    } = getState();

    for (let i = singleResumableInstance.files.length; i--; ) {
      const fileObj = {
        ...singleResumableInstance.files[i],
        providerID: contentChannelData[0]?.providerId,
        provider: contentChannelData[0]?.providerName,
        //channelId : contentChannelData[0]?.tag,
        tag: currentTagId?.titleTag,
        creationDate: currentTagId?.creationDate,
        videoStatus: VIDEO_STATUS_ENUM.UPLOADING,
        status: CONTENT_STATUS_ENUM.INCOMPLETE,
      };
      items[i] = createNewTitle(fileObj, isFileBroken);
    }

    let lisToDisplay = [...TitlesBeingUploaded, ...items];
    lisToDisplay = lisToDisplay.filter(
      (v, i, a) => a.findIndex((t) => t.className === v.className) === i
    );
    dispatch(getContentTitlesBeingUploaded(lisToDisplay));
  }

  singleResumableInstance.on("fileAdded", (file, event) => {
    dispatch(setUploadStatusToTrue(true));
    if (interval) {
      clearInterval(interval);
    }
    let optionalPaylad = [] as any;
    if (isReplacing === false) {
      if (isFileBroken === true) {
        if (brokenFilesList?.length > 1) {
          optionalPaylad = brokenFilesList?.filter(
            (title) => title.className !== file.fileName
          );
        }

        let chunks = file.chunks as any;
        if (chunks) {
          for (let i = 0; i < lastBrokenChunk; i++) {
            if (chunks[i]) {
              chunks[i]["preprocessState"] = 2;
            }
          }
        }
      }

      if (isFileBroken === false && brokenFilesList?.length > 0) {
        optionalPaylad = brokenFilesList;
      }
    }

    singleResumableInstance.upload();

    function myCallback(ResumableInstance, isReplacing) {
      if (isReplacing === false) {
        if (window.navigator.onLine) {
          if (ResumableInstance.isUploading() === false) {
            //
            ResumableInstance.upload();
          }
          let files = ResumableInstance.files;
          let newTitleList = [] as any;
          let isAnyFileCompleted;
          const {
            layout: { contentChannelData },
            contentTitles: { currentTagId, TitlesBeingUploaded, uploadTags },
          } = getState();
          for (let l = 0; l < files.length; l++) {
            let file = files[l];

            if (file.isComplete() && files.length > 1) {
              isAnyFileCompleted = true;
              try {
                ResumableInstance.removeFile(file);
                // removing file from ResumableJs instance
                deletTitlesQueue(file.fileName);
              } catch (err) {
                //console.log(err);
              }
            } else {
              const fileObj = {
                ...file,
                providerID: contentChannelData[0]?.providerId,
                provider: contentChannelData[0]?.providerName,
                //channelId : contentChannelData[0]?.tag,
                videoStatus: VIDEO_STATUS_ENUM.UPLOADING,
                status: CONTENT_STATUS_ENUM.INCOMPLETE,
              };
              if (
                file?.file?.uniqueIdentifier === currentTagId?.uniqueIdentifier
              ) {
                fileObj.tag = currentTagId?.titleTag;
                fileObj.creationDate = currentTagId?.creationDate;
              } else {
                const currentFileInfo = find(uploadTags, {
                  uniqueIdentifier: file?.file?.uniqueIdentifier,
                });
                fileObj.tag = currentFileInfo?.titleTag;
                fileObj.creationDate = currentFileInfo?.creationDate;
              }
              newTitleList.push(createNewTitle(fileObj, isFileBroken));
            }
          }
          let finailListToDisplay;
          const fileDetail = find(TitlesBeingUploaded, {
            className: newTitleList[0]?.className,
          });
          if (fileDetail)
            finailListToDisplay = map(TitlesBeingUploaded, (_) =>
              _.className === newTitleList[0].className
                ? { ...newTitleList[0] }
                : { ..._ }
            );
          else finailListToDisplay = [...TitlesBeingUploaded, ...newTitleList];

          if (isAnyFileCompleted === true) {
            localStorage.setItem(
              "uploads",
              JSON.stringify(finailListToDisplay)
            );
            const isUserOnEditScreen = window.location.pathname.includes(
              "/media/contentTitles/edit/"
            );
            dispatch(
              filterContentListfromApi(
                paginationValues.DAFAULT_PAGE_NUMBER,
                paginationValues.DEFAULT_PAGE_SIZE_FOR_CONTENT,
                "",
                "",
                finailListToDisplay,
                true,
                localStorage.getItem("csTenantId") ?? "",
                isUserOnEditScreen ? currentTagId?.titleTag ?? tag ?? "" : "",
                isUserOnEditScreen ? false : true
              )
            );
            dispatch(getContentTitlesBeingUploaded(finailListToDisplay));
            isAnyFileCompleted = false;
          } else {
            dispatch(getContentTitlesBeingUploaded(finailListToDisplay));

            localStorage.setItem(
              "uploads",
              JSON.stringify(finailListToDisplay)
            );
            localStorage.setItem(
              "progress",
              JSON.stringify(file.progress(true))
            );
            // localStorage.setItem("uploading", "true");
          }
        } else {
          if (ResumableInstance.isUploading() === true) {
            ResumableInstance.pause(); //
          }
        }
      } else {
        let file = ResumableInstance.files[0];
        const uploadFiles = filter(file.chunks, { loaded: 0 }).length;
        const totalFiles = file.chunks.length;
        const fileProgess = file.progress();
        const getProgess =
          fileProgess === 0 ? uploadFiles / totalFiles : fileProgess;
        const {
          contentTitles: { replaceVideoUploadStatus },
        } = getState();
        let tempReplacingVideos = [...replaceVideoUploadStatus];
        const taggedVideo = tempReplacingVideos.findIndex(i => i.tag === tag);
        if (taggedVideo !== -1) {
          tempReplacingVideos[taggedVideo] = { progress: getProgess, tag: tag };
        } else {
          tempReplacingVideos.push({ progress: getProgess, tag: tag });
        }
        dispatch(setReplaceVideoStatus(tempReplacingVideos));
      }
    }
    interval = setInterval(
      myCallback,
      3000,
      singleResumableInstance,
      isReplacing
    );
  });
  singleResumableInstance.on("cancel", () => {
    clearInterval(interval);
  });
  singleResumableInstance.on("error", (message: string, file: any) => {
    clearInterval(interval);
    let files = singleResumableInstance.files;
    let newTitleList = [] as any;
     
    for (let l = 0; l < files.length; l++) {
      let file = files[l];
      if(tag !== null){
        file.tag = tag;
      }
      newTitleList.push(createNewTitle(file, true, true));
    }
     //dispatch(getContentTitlesBeingUploaded(newTitleList));
     dispatch(setBrokenFileBeingUploaded({ status: false, message: "" }));
  });

  singleResumableInstance.on("complete", (file) => {
    const isUserOnEditScreen = window.location.pathname.includes(
      contentTitlesEdit
    );
    if (isReplacing === false) {
      dispatch(setBrokenFileBeingUploaded({ status: false, message: "" }));

      let fileName = file?.fileName;
      let files = singleResumableInstance.files;
      let newBrokenFilelist = [];
      try {
        deletTitlesQueue(fileName);
      } catch (err) {
        //console.log(new Date() + "File Uploaded completed :" + err);
      }
      try {
        if (brokenFilesList !== null) {
          newBrokenFilelist = brokenFilesList.filter(
            (f) => f.className !== file.fileName
          );
        }
      } catch (err) {
        //console.log(new Date() + "File Uploaded completed :" + err);
      }
      try {
        let index;
        for (let i = 0; i < files?.length; i++) {
          if (files[i].file["identifier"] === file?.file["identifier"]) {
            index = i;
          }
        }
        singleResumableInstance.removeFile(
          singleResumableInstance.files[index]
        );
      } catch (err) {
        //console.log(new Date() + "File Uploaded completed :" + err);
      }
      const {
        layout: { contentChannelData },
        contentTitles: { currentTagId, TitlesBeingUploaded, uploadTags },
      } = getState();
      const updateFileStatus = map(TitlesBeingUploaded, (_: any) => {
        if (_.className !== file.fileName) return _;
        return {
          ..._,
          videoStatus: VIDEO_STATUS_ENUM.UPLOADED,
          contentStatus: CONTENT_STATUS_ENUM.COMPLETE,
          tag: currentTagId?.titleTag ?? null,
          creationDate: currentTagId?.creationDate ?? null,
        };
      });
      dispatch(
        filterContentListfromApi(
          paginationValues.DAFAULT_PAGE_NUMBER,
          paginationValues.DEFAULT_PAGE_SIZE_FOR_CONTENT,
          "",
          "",
          updateFileStatus,
          true,
          localStorage.getItem("csTenantId") ?? "",
          isUserOnEditScreen ? currentTagId.titleTag ?? tag : "",
          isUserOnEditScreen ? false : true
        )
      );
      if (singleResumableInstance?.files?.length === 0) {
        clearInterval(interval);
        //console.log("=====>>>>>> uplaoded<<<<<<<<<<===========");
        localStorage.setItem("uploads", "");
        dispatch(setUploadStatusToTrue(false));
      }
    } else {
      const {
        contentTitles: {
          currentTagId,
          selectedContentTitleRows: { list, videoStatus, isReupload },
        },
        channel: {
          tenantsData: { tenants },
        },
      } = getState();
      const [tenant] = tenants;
      const [selectedRecord] = list;
      if (localStorage.getItem("csTenantId") !== null) {
        dispatch(
          filterContentListfromApi(
            paginationValues.DAFAULT_PAGE_NUMBER,
            paginationValues.DEFAULT_PAGE_SIZE_FOR_CONTENT,
            "",
            "",
            "",
            true,
            localStorage.getItem("csTenantId"),
            isUserOnEditScreen ? currentTagId?.titleTag ?? tag : "",
            isUserOnEditScreen ? false : true
          )
        );
      } else {
        dispatch(
          filterContentListfromApi(
            paginationValues.DAFAULT_PAGE_NUMBER,
            paginationValues.DEFAULT_PAGE_SIZE_FOR_CONTENT,
            "",
            "",
            "",
            true,
            tenant?.id ?? "",
            isUserOnEditScreen ? currentTagId?.titleTag ?? tag : "",
            isUserOnEditScreen ? false : true
          )
        );
      }
      // check video is reupload
      clearInterval(interval);
      const {
        contentTitles: { replaceVideoUploadStatus },
      } = getState();
      let tempReplacingVideos = [...replaceVideoUploadStatus];
      tempReplacingVideos = tempReplacingVideos.filter(i => i.tag !== tag);
      dispatch(setReplaceVideoStatus(tempReplacingVideos));
      dispatch(setReuploadVideo(false));
      let reUploadTagsTemp = [ ...reuploadTags ];
      reUploadTagsTemp = reUploadTagsTemp.filter(i => i !== tag);
      dispatch(setReuploadTags(reUploadTagsTemp));
    }
    singleResumableInstance.opts.target = ApiConstants.replaceTitleVideoAPI;
  });
  let updateChunk = 0;
  singleResumableInstance.on("catchAll", (eventName: string, res: any) => {
    if (eventName === "chunkFinished" && res !== "" && updateChunk === 0) {
      try {
        // check whether it should not be first chunk call
        const result = JSON.parse(res);
        updateChunk++;
        //Code for update tag in titlebeing upload
        const {
          channel,
          contentTitles: { TitlesBeingUploaded, isLoading, currentTagId },
        } = getState();
        if (result.titleTag !== null) {
          //** Solved duplicate entry in listing */
          // 1. Find the TitlesBeingUploaded with the className
          const currentTtitleIndex = TitlesBeingUploaded.findIndex(
            (titles) => titles.className === File[index]?.name
          );

          // 2. Mark the tag n creation date as titleTag from first chunk response
          const updatedTitleBeingUpload = {
            ...TitlesBeingUploaded[currentTtitleIndex],
            tag: result.titleTag,
            creationDate: result.creationDate,
          };
          // 3. Update the TitlesBeingUploaded list with the updated TitlesBeingUploaded
          const newTitlesBeingUploaded = [
            ...TitlesBeingUploaded.slice(0, currentTtitleIndex),
            updatedTitleBeingUpload,
            ...TitlesBeingUploaded.slice(currentTtitleIndex + 1),
          ];
          dispatch(getContentTitlesBeingUploaded(newTitlesBeingUploaded));
        }

        dispatch(
          setCurrentTagId({
            titleTag: result.titleTag,
            creationDate: result.creationDate,
            uniqueIdentifier: File[index].uniqueIdentifier,
          })
        );
        singleResumableInstance.opts.target =
          singleResumableInstance.opts.target + result.titleTag;
        ResumableInstance[result.titleTag] = singleResumableInstance;
      } catch (e) {
        //console.info("RESULT",res);
      }
    }
    if (eventName === "ChunkComplete") {
      const fileIndex = findIndex(res.resumableObj.files, {
        fileName: res.fileName,
      });
      const isUserOnEditScreen = window.location.pathname.includes(
        "/media/contentTitles/edit/"
      );
      if (fileIndex !== -1) {
        let fileObj = res.resumableObj.files[fileIndex];
        const shouldContentTitleCall = contentTitleListCall(fileObj);
        const {
          channel,
          contentTitles: { TitlesBeingUploaded, isLoading, currentTagId },
        } = getState();
        //!pending get role wise providerID and tenants ,,,,tag:ChannelId ,
        const { providerId, tenants } = channel?.channelData?.channels[0];
        shouldContentTitleCall &&
          !isLoading &&
          dispatch(
            filterContentListfromApi(
              paginationValues.DAFAULT_PAGE_NUMBER,
              paginationValues.DEFAULT_PAGE_SIZE_FOR_CONTENT,
              "",
              "",
              TitlesBeingUploaded,
              true,
              localStorage.getItem("csTenantId") ?? "",
              isUserOnEditScreen ? currentTagId?.titleTag ?? tag : "",
              isUserOnEditScreen ? false : true
            )
          );
      }
    }
  });
  singleResumableInstance.on("ChunkComplete", (result: any) => {
    if (isReplacing === false) {
      let files = singleResumableInstance.files;

      let newTitleList = [] as any;
      for (let l = 0; l < files.length; l++) {
        let file = files[l];
        {
          const {
            layout: { contentChannelData },
            contentTitles: { uploadTags, currentTagId },
          } = getState();
          const providerInfo: any = {
            providerID: contentChannelData[0]?.providerId,
            provider: contentChannelData[0]?.providerName,
            //channelId : contentChannelData[0]?.tag,
            videoStatus: VIDEO_STATUS_ENUM.UPLOADING,
            status: CONTENT_STATUS_ENUM.INCOMPLETE,
            tag: currentTagId?.titleTag ?? null,
            creationDate: null,
          };
          const currentInfo: any = find(uploadTags, {
            uniqueIdentifier: file.uniqueIdentifier,
          });
          providerInfo.tag = currentInfo?.titleTag ?? null;
          providerInfo.creationDate = currentInfo?.creationDate ?? null;
          const fileData = { ...file, ...providerInfo };
          newTitleList[l] = createNewTitle(fileData);
        }
      }
      const {
        contentTitles: { TitlesBeingUploaded },
      } = getState();
      let displayTitlesOnChunkUpload;
      const fileDetail = find(TitlesBeingUploaded, {
        className: newTitleList[0].className,
      });
      if (fileDetail)
        displayTitlesOnChunkUpload = map(TitlesBeingUploaded, (_) =>
          _.className === newTitleList[0].className
            ? { ...newTitleList[0] }
            : { ..._ }
        );
      else
        displayTitlesOnChunkUpload = [...TitlesBeingUploaded, ...newTitleList];
      dispatch(getContentTitlesBeingUploaded(displayTitlesOnChunkUpload));

      localStorage.setItem("uploads", JSON.stringify(newTitleList));
      localStorage.setItem("progress", JSON.stringify(result.progress(true)));
    }
  });

  singleResumableInstance.on("AuthError", (file: any, message: string) => {
    dispatch(refreshTokenWithoutCallback());
  });
};

export const resetUploadStatusforNewFiles = (): AppThunk => (dispatch) => {
  getQueuedTitles().then((titles: any) => {
    if (titles?.length > 0) {
      //console.log("other titles are remaing in db");
    } else {
      dispatch(setNewFileBeingUploaded({ status: false, message: "" }));
    }
  });
};
export const setReuploadStatus = (tagId: string): AppThunk => (
  dispatch,
  getState
) => {
  const {
    contentTitles: { selectedContentTitleRows },
  } = getState();
  const currentRecord = selectedContentTitleRows?.list[0];
  if (
    includes(VIDEO_STATUS_REUPLOAD, currentRecord?.trailerStatus) &&
    tagId === currentRecord?.tag
  )
    dispatch(setContentTitleSelectedRowReuploadStatus());
};
export const resumableTrailerUploader = (
  File: any,
  Event: any,
  tag: string,
  isReplacing: boolean,
  eventData: any = null
): AppThunk => (dispatch, state) => {
  let URL = ApiConstants.uploadTrailerVideoAPI + tag;

  if (eventData !== null) {
    URL = ApiConstants.uploadEventTrailerAPI + eventData.event_Id;
  }

  if (isReplacing && eventData == null) {
    URL = ApiConstants.replaceTrailerVideoAPI + tag;
  }

  const timeStamp = Date.now();
  File[0]["identifier"] = `${timeStamp}-${File[0].name}`; // Rajneesh, need to move all duplicate code inn utility functionn

  let ResumableTraileField = createResumableInstance(
    File,
    Event,
    URL,
    isReplacing
  );

  let interval;

  let singleResumableInstance = ResumableTraileField;
  singleResumableInstance.appendFilesFromFileList(File, Event);

  singleResumableInstance.on("fileAdded", (file, event) => {
    singleResumableInstance.upload();

    function myCallback(ResumableInstance) {
      let file = ResumableInstance.files[0];

      dispatch(
        setTrailerProgess({
          progress: file ? file.progress(false) : 0,
          tag: tag,
        })
      );
    }
    interval = setInterval(myCallback, 1500, singleResumableInstance);
  });

  singleResumableInstance.on("error", (file: any, message: string) => {
    /**
     * file cancelled toast coming so not needed and commented
     */
    //setErrorInTrailorUploads({ error: "Error : file Not Uploaded" });
    dispatch(setTrailerProgess({}));
  });

  singleResumableInstance.on("complete", (file) => {
    singleResumableInstance.removeFile(file);
    const getState: any = state();
    const {
      contentTitles: { TitlesBeingUploaded, uploadTags },
    } = getState;
    const isUserOnEditScreen = window.location.pathname.includes(
      contentTitlesEdit
    );
    const tenantId = localStorage.getItem("csTenantId");
    if (isEmpty(getState.contentTitles.ErrorInTrailerUploads)) {
      dispatch(setTrailerProgess({ progress: 1, tag: tag }));
    } else {
      dispatch(setTrailerProgess({}));
      setTimeout(() => {
        dispatch(setErrorInTrailorUploads({}));
      }, 5000);
    }
    dispatch(
      filterContentListfromApi(
        paginationValues.DAFAULT_PAGE_NUMBER,
        paginationValues.DEFAULT_PAGE_SIZE_FOR_CONTENT,
        "",
        "",
        "",
        true,
        tenantId ?? "",
        isUserOnEditScreen ? tag : "",
        isUserOnEditScreen ? false : true
      )
    );
    clearInterval(interval);
  });
};

export const reTriggerEncoding = (
  tag: string,
  isEventVideo: boolean = false,
  tenantId: string = "",
  csTenantId: any
): AppThunk => (dispatch) => {
  if (isEventVideo) {
    if (csTenantId != null) {
      userApi
        .post(ApiConstants.reTriggerEventVideoEncodingAPI + tag, {
          tenantId: csTenantId,
        })
        .then(() => {
          dispatch(getScheduleEvents(csTenantId, ""));
        });
    } else {
      tenantHeaderTransform(tenantId as any);
      tenantHeaderApi
        .post(ApiConstants.reTriggerEventVideoEncodingAPI + tag)
        .then(() => {
          dispatch(getScheduleEvents(tenantId, ""));
        });
    }
  } else {
    if (csTenantId != null) {
      userApi
        .post(ApiConstants.reTriggerTitleEncodingAPI + tag, {
          tenantId: csTenantId,
        })
        .then(() => {
          // dispatch(filterContentListfromApi(1, 20, '', ''));
        });
    } else {
      userApi.post(ApiConstants.reTriggerTitleEncodingAPI + tag).then(() => {
        // dispatch(filterContentListfromApi(1, 20, '', ''));
      });
    }
  }
};
export const setError = (showError = true): AppThunk => (
  dispatch,
  getState
) => {
  const {
    contentTitles: {
      selectedContentTitleRows: { isError, msg },
    },
  } = getState();
  const message = MEDIA_NOT_MATCHED_VIDEO_NOT_EXIST.MESSAGE;
  if (showError)
    dispatch(setContentTitleRowEmpty({ isError: true, msg: message }));
  if (isError && !isEmpty(msg) && msg === message)
    dispatch(setContentTitleRowEmpty({ isError: false, msg: "" }));
};
export const getTitleStreamingLinkfromApi = (
  providerId: any,
  tenantId: any,
  titleTag: any
): AppThunk => (dispatch) => {
  dispatch(setTitleStreamingLinkLoading(true));
  streamingLinkApiHeaderTransform(tenantId);
  streamingLinkApi
    .get(`${ApiConstants.getTitleStreamingLinkAPI}/${providerId}/${titleTag}`)
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        if (isEmpty(res.data.streamingLink)) {
          dispatch(setError());
        } else {
          dispatch(setTitleStreamingLink(res.data));
          dispatch(setError(false));
        }
      } else if (
        includes(
          [apiResponseEnums.NOT_FOUND, apiResponseEnums.BAD_REQUEST],
          res.status
        )
      ) {
        const message = MEDIA_NOT_MATCHED_VIDEO_NOT_EXIST.MESSAGE;
        dispatch(setApiResponse({ status: res.status, data: message, autoHide: false }));
      } else if (res.status === apiResponseEnums.UNAUTHRISED) {
        dispatch(
          regfeshTokenId(
            getTitleStreamingLinkfromApi(providerId, tenantId, titleTag)
          )
        );
      } else {
        dispatch(setTitleStreamingLink({ err: "" }));
      }
    })
    .finally(() => {
      dispatch(setTitleStreamingLinkLoading(false));
      dispatch(setError(false));
    });
};
export const updateCaption = (formData): AppThunk => (dispatch) => {
  dispatch(setCaptionsCreateLoading(true));
  dispatch(setCaptionsSaveSuccess(false));
  userApi
    .post(`${ApiConstants.updateCaptionsAPI}`, formData)
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(setCaptionsSaveSuccess(true));
        dispatch(
          setApiResponse({ status: res.status, data: USER_MESSAGES.SAVED })
        );
      } else if (res.status === apiResponseEnums.UNAUTHRISED) {
        dispatch(regfeshTokenId(addCaption(formData)));
      } else {
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    })
    .finally(() => {
      dispatch(setCaptionsCreateLoading(false));
    });
};
export const addCaption = (formData): AppThunk => (
  dispatch
) => {
  dispatch(setCaptionsCreateLoading(true));
  dispatch(setCaptionsSaveSuccess(false));
  userApi
    .post(`${ApiConstants.createCaptionsAPI}`, formData)
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(setCaptionsSaveSuccess(true));
        dispatch( setApiResponse({ status: res.status, data: USER_MESSAGES.SAVED }) );
      } else if (res.status === apiResponseEnums.UNAUTHRISED) {
        dispatch(regfeshTokenId(addCaption(formData)));
      } else {
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage({
              status: res.status as number,
              data: res.data as ErrorObjectType,
            }),
          })
        );
      }
    })
    .finally(() => {
      dispatch(setCaptionsCreateLoading(false));
    });
};

export const deleteCaption = (data, tenantId, resetSelectedCallback): AppThunk => (dispatch) => {
  userApi
    .delete(
      `${ApiConstants.deleteCaptionsAPI}`,
      {},
      {
        data,
      }
    )
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(getContentTitle(data.titleTag, false, false, tenantId));
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.ITEM_DELETE,
          })
        );
        resetSelectedCallback();
      } else {
        dispatch(
          setApiResponse({
            status: res.status ?? apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage(res),
          })
        );
      }
    });
};
export const getAutoGeneratedThumbnail = (payload : AutoCoverImagePayloadType) : AppThunk => (dispatch) => {
  dispatch(setAutoGeneratedThumbnailLoader(true));
  userApi
    .put(ApiConstants.autoGenerateCoverImageAPI, payload)
    .then((res: any) => {
      if (res.status === apiResponseEnums.CREATED) {
        dispatch(setAutoGeneratedThumbnail(res.data));
      } else {
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage(res),
          })
        );
      }
    })
    .finally(() => {
      dispatch(setAutoGeneratedThumbnailLoader(false));
    });
};

export const concurrentUploadNumber = (state: RootState) =>
  state.contentTitles.concurrentUploadNumber;
export const TrailerBeingUploadedStatus = (state: RootState) =>
  state.contentTitles.TrailerUploadProgress;
export const TileBeingUploadedStatus = (state: RootState) =>
  state.contentTitles.TileBeingUploadedStatus;
export const TitlesBeingUploaded = (state: RootState) =>
  state.contentTitles.TitlesBeingUploaded;

export const replaceVideoUploadStatus = (state: RootState) =>
  state.contentTitles.replaceVideoUploadStatus;

export const networkStatus = (state: RootState) =>
  state.contentTitles.networkStatus;

export const showOnlyUploadButton = (state: RootState) =>
  state.contentTitles.showOnlyUploadButton;

export const isUploadGoingOn = (state: RootState) =>
  state.contentTitles.isUploadGoingOn;
export const contentStatus = (state: RootState) =>
  state.contentTitles.contentStatus;
export const contentTitleDetailStatus = (state: RootState) =>
  state.contentTitles.status;
export const selectMetaData = (state: RootState) =>
  state.contentTitles.metaData;
export const selectTitles = (state: RootState) =>
  state.contentTitles.contentTitlesData;

export const channelCreatedStatus = (state: RootState) =>
  state.channel.isChannelCreated;

export const channelPublishingStatus = (state: RootState) =>
  state.channel.isChannelPublished;
export const fileUploadStatus = (state: RootState) =>
  state.channel.isFileUploaded;
export const fileUrl = (state: RootState) => state.channel.fileUrl;
export const channelCreatedError = (state: RootState) => state.channel.error;
export const publishingError = (state: RootState) =>
  state.contentTitles.publishingError;

export const errorInTrailerUpload = (state: RootState) =>
  state.contentTitles.ErrorInTrailerUploads;
export const uploadErrors = (state: RootState) =>
  state.contentTitles.ErrorInUploads;

export const isBrokenFileBeingUploaded = (state: RootState) =>
  state.contentTitles.isBrokenFileBeingUploaded;

export const isMetaDataFormUpdated = (state: RootState) =>
  state.contentTitles.isMetaDataFormUpdated;

export const blockNewUploads = (state: RootState) =>
  state.contentTitles.blockNewUploads;

export const selectLanguage = (state: RootState) =>
  state.contentTitles.language;

export const filteredContentTitles = (state: RootState) =>
  state.contentTitles.filteredContentTitles;

export const enteredQuery = (state: RootState) =>
  state.contentTitles.enteredQuery;
export const getContentLoading = (state: RootState) =>
  state.contentTitles.isLoading;
export const titleStreamingLink = (state: RootState) =>
  state.contentTitles.streamingLink;
export const titleStreamingLinkLoading = (state: RootState) =>
  state.contentTitles.streamingLinkLoader;
export const getSelectedContentTitleRows = (state: RootState) =>
  state.contentTitles.selectedContentTitleRows;
export const getCurrentTagId = (state: RootState) =>
  state.contentTitles.currentTagId;
export const getUploadedTagIds = (state: RootState) =>
  state.contentTitles.uploadTags;

export const getCaptionsList = (state: RootState) =>
  state.contentTitles.uploadTags;

export const getCaptionsCreateLoader = (state: RootState) =>
         state.contentTitles.captionsCreateLoader;

export const getCaptionsSaveSuccess = (state: RootState) =>
         state.contentTitles.captionsSaveSuccess;

export const exportTitlesLoader = (state: RootState) =>
         state.contentTitles.loadingFile;

export const autoGeneratedCoverImageLoading = (state: RootState) =>
         state.contentTitles.autoGeneratedCoverImageLoading;
export const autoGeneratedCoverImage = (state: RootState) =>
         state.contentTitles.autoGeneratedCoverImage;

export default contentTitleSlice.reducer;
