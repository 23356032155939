import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScrollTop } from "utils/utilityFunctions";
import {
  updateWebSettingsTenant,
  getLoadingAPIData,
} from "_pages/tenants/tenantSlice";
import { isEmpty, omit } from "lodash";
import {
  SUBSCRIPTION_SOURCE,
  SiteSourceType,
  TENANT_MESSAGES,
  NO_SUBSCRIPTION_COUNT_HOME_PAGE,
  ACTIVATED_PRODUCT_TYPES_OBJECTS,
} from "constants/enums";
import { useParams } from "react-router";
import {
  WebSettingsFormData,
  WebFormProps,
  Fields,
} from "models/Tenants/TenantsGeneralSettingsModel";
import { useHistory } from "react-router";
import { isEqual } from "lodash";

export const useTenantWebSettingsForm = (props: WebFormProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const initialState: WebSettingsFormData = {
    siteSourceType: "",
    subscriptionSourceType: [],
    stripe: false,
    mms: false,
    pseudosso: false,
    flatfile: false,
    lockedContentBehaviour: "",
    isWexerOwnedCategories: false,
    isMarketingConsent: false,
    projectId: "",
    url: "",
    localyticsWebId: "",
    secretKey: "",
    webHookKey: "",
    planId: "",
    publishableKey: "",
    pricingTableId: "",
    numberOfMySubscriptionItemsOnHomePage: NO_SUBSCRIPTION_COUNT_HOME_PAGE,
    landingPageGetAccessLink: "",
    showLogo: false,
    showFooter: false,
    isLanguageSwitcher: false,
    ratingEnabled: false,
    scheduleEvents: false,
    fitRadioEnabled: false,
  };
  const apiDataLoading = useSelector<boolean>(getLoadingAPIData);
  const [apiData, setFromAPIData] = useState<WebSettingsFormData>({
    ...initialState,
  });
  const [formData, setFormData] = useState<WebSettingsFormData>({
    ...initialState,
  });
  const [errors, setError] = useState({
    ...omit(initialState, "numberOfMySubscriptionItemsOnHomePage"),
  });

  const [isLoading, setLoading] = useState<boolean>(false);
  const { id } = useParams<{
    id: string;
  }>();
  const [warningModal, setWarningModal] = useState<boolean>(false);

  const [isSameFormData, setIsSameFormData] = useState(false);
  const [isFormDataChanged, setIsFormDataChanged] = useState(false);
  const [unsavedModal, setUnsavedModal] = useState(false);
  const [tabRoutingPath, setTabRoutingPath] = useState<string>("");

  useEffect(() => {
    return () => setFormData({ ...initialState });
  }, []);

  useEffect(() => {
    ScrollTop();
  }, []);

 useEffect(() => {
   if (!props.webSettingsData) {
     return;
   }

   const selectedTenantWS = props.webSettingsData;
   const subscriptionSources = selectedTenantWS?.subscriptionSource || [];
   const subscriptionSourcesLower = subscriptionSources.map((s) =>
     s.toLowerCase()
   );

   const updatedData = {
     ...selectedTenantWS,
     showFooter: selectedTenantWS?.showFooter || false,
     showLogo: selectedTenantWS?.showLogo || false,
     stripe: subscriptionSourcesLower.includes(
       SUBSCRIPTION_SOURCE.STRIPE.toLowerCase()
     ),
     mms: subscriptionSourcesLower.includes(
       SUBSCRIPTION_SOURCE.MMS.toLowerCase()
     ),
     pseudosso: subscriptionSourcesLower.includes(
       SUBSCRIPTION_SOURCE.PSUEDO_SSO.toLowerCase()
     ),
     flatfile: subscriptionSourcesLower.includes(
       SUBSCRIPTION_SOURCE.FILE_UPLOAD.toLowerCase()
     ),
   };

   setFromAPIData(updatedData);
   setFormData({
     ...formData,
     ...updatedData,
   });
 }, [props.webSettingsData]);

  useEffect(() => {
    if (apiDataLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [props.webSettingsData, apiDataLoading]);

  const getErrorText = (stateData: any = false) => {
    const frmData: any = stateData ? stateData : formData;
    let errObj: any = {};

    const requiredFields = [
      {
        field: Fields.SITE_SOURCE_TYPE,
        message: TENANT_MESSAGES.SITE_TYPE_REQUIRED,
      },
      {
        field: Fields.LOCKED_CONTENT_BEHAVIOUR,
        message: TENANT_MESSAGES.LOCK_CONTENT_BEHAVIOUR_REQUIRED,
      },
      {
        field: Fields.PROJECT_ID,
        message: TENANT_MESSAGES.PO_EDIOTR_PROJECT_ID_REQUIRED,
      },
      { field: Fields.URL, message: TENANT_MESSAGES.WEB_PLAYER_URL_REQUIRED },
      {
        field: Fields.LOCALYTICS_WEB_ID,
        message: TENANT_MESSAGES.LOCALYTICS_WEB_ID_REQUIRED,
      },
      {
        field: Fields.LANDING_PAGE_GET_ACCESS_LINK,
        message: TENANT_MESSAGES.LANDING_PAGE_GET_ACCESS_LINK_REQUIRED,
      },
      {
        field: Fields.NUMBER_OF_MY_SUBSCRIPTION_ITEMS_ON_HOME_PAGE,
        message: TENANT_MESSAGES.HOMEPAGE_SUBSCRIPTION_COUNT_REQUIRED,
      },
    ];

    const checkFields = (fields: any[]) => {
      fields.forEach(({ field, message }) => {
        if (!frmData[field] || frmData[field].length === 0) {
          errObj[field] = message;
        }
      });
    };

    checkFields(requiredFields);

    const noSubscriptionSourceSelected =
      !frmData.mms &&
      !frmData.flatfile &&
      !frmData.stripe &&
      !frmData.pseudosso;
    const isFacOrSub =
      frmData.siteSourceType === SiteSourceType.fac ||
      frmData.siteSourceType === SiteSourceType.sub;
    const isFacAndNoStripe =
      frmData.siteSourceType === SiteSourceType.fac && !frmData.stripe;
    const isSubAndNoSubscriptionSource =
      frmData.siteSourceType === SiteSourceType.sub &&
      noSubscriptionSourceSelected;
    const isStripeAndFacOrSub = frmData.stripe && isFacOrSub;

    if (noSubscriptionSourceSelected && isFacOrSub) {
      errObj.subscriptionSourceType =
        TENANT_MESSAGES.SUBSCRIPTION_SOURCE_REQUIRED;
    }
    if (isFacAndNoStripe) {
      errObj.subscriptionSourceType = TENANT_MESSAGES.STRIPE_MANDATORY;
    }
    if (isSubAndNoSubscriptionSource) {
      errObj.subscriptionSourceType =
        TENANT_MESSAGES.SUBSCRIPTION_SOURCE_ONE_REQUIRED;
    }
    if (isStripeAndFacOrSub) {
      const stripeRequiredFields = [
        {
          field: Fields.SECRET_KEY,
          message: TENANT_MESSAGES.STRIPE_SECRET_KEY_REQUIRED,
        },
        {
          field: Fields.WEB_HOOK_KEY,
          message: TENANT_MESSAGES.STRIPE_WEBHOOK_SECRET_REQUIRED,
        },
        {
          field: Fields.PUBLISHABLE_KEY,
          message: TENANT_MESSAGES.STRIPE_PUBLISHABLE_KEY_REQUIRED,
        },
      ];

      checkFields(stripeRequiredFields);

      if (frmData.siteSourceType === SiteSourceType.sub) {
        checkFields([
          {
            field: Fields.PRICING_TABLE_ID,
            message: TENANT_MESSAGES.STRIPE_PRICE_ID_REQUIRED,
          },
        ]);
      }

      if (frmData.siteSourceType === SiteSourceType.fac) {
        checkFields([
          {
            field: Fields.PLAN_ID,
            message: TENANT_MESSAGES.STRIPE_PLAN_ID_REQUIRED,
          },
        ]);
      }
    }

    setError(errObj);
    return errObj;
  };

  const getCreateApiPayload = (data) => {
    const subscriptionSourceType = [
      SUBSCRIPTION_SOURCE.STRIPE,
      SUBSCRIPTION_SOURCE.MMS,
      SUBSCRIPTION_SOURCE.PSUEDO_SSO,
      SUBSCRIPTION_SOURCE.FILE_UPLOAD,
    ];
    const trueKeys = subscriptionSourceType.filter((key) => data[key]);
    const apiPayload = {
      ...data,
      subscriptionSource: trueKeys.length > 0 ? trueKeys : [],
      tenantID: id,
    };
    delete apiPayload?.subscriptionSourceType;
    return apiPayload;
  };
  const submitForm = () => {
    const isError = getErrorText();
    if (isEmpty(isError)) {
      setLoading(true);
      let apiCreatePayload = getCreateApiPayload(formData);
      const activateApp = props?.tntByIdData?.productList
        .map((product) => product.toLowerCase())
        .includes(ACTIVATED_PRODUCT_TYPES_OBJECTS.web.toLowerCase());

      if (!activateApp) {
        setWarningModal(true);
        setLoading(false);
        return;
      } 
      dispatch(updateWebSettingsTenant(apiCreatePayload));
      setIsFormDataChanged(false);
    }
  };

  const handleWarningModalClose = () => {
    setWarningModal(false);
  };

  const showModalBox = (routingPath) => {
    setUnsavedModal(true);
    setTabRoutingPath(routingPath);
  };

  const handleUnsavedModalOkClick = () => {
    history.push(tabRoutingPath);
  };
  const handleUnsavedModalClose = () => {
    setUnsavedModal(false);
  }

    useEffect(() => {
      if (isFormDataChanged === true && apiData && formData) {
        if (isEqual(apiData, formData)) {
          setIsSameFormData(false);
        } else {
          setIsSameFormData(true);
        }
      } else {
        setIsSameFormData(false);
      }
    }, [isFormDataChanged, formData, apiData]);


  return {
    formData,
    setFormData,
    errors,
    getErrorText,
    setLoading,
    isLoading,
    submitForm,
    initialState,
    warningModal,
    handleWarningModalClose,
    isSameFormData,
    setIsSameFormData,
    showModalBox,
    unsavedModal,
    setIsFormDataChanged,
    handleUnsavedModalOkClick,
    handleUnsavedModalClose,
  };
};
