export const ImageUploadMatadata = {
         PROFILE_IMAGE: {
           FILE_TYPES: ".jpg, .jpeg, .png",
           ID: "PROFILE_IMAGE",
           META: "160x160px - jpg or png",
           ASPECT_RATIO: 160 / 160,
           ERROR_MESSAGE: {
             ASPECT_RATIO: "The dimension of image must be 160 x 160",
             FILE_TYPE:
               "Please select a valid jpg/jpeg/png format file for upload.",
           },
         },
         CATEGORY_IMAGE: {
           FILE_TYPES: ".jpg, .png",
           WIDTH: 500,
           HEIGHT: 500,
           ID: "CATEGORY_IMAGE",
           META: "",
           ASPECT_RATIO: 500 / 500,
           ERROR_MESSAGE: {
             ASPECT_RATIO: "The dimension of image must be 500 x 500",
             FILE_TYPE: "Please select a valid jpg/png format file for upload.",
           },
         },
         MEDIA_SPACE: {
           FILE_TYPES: ".jpg, .jpeg",
           ID: "MEDIA_IMAGE",
           META: "1920x564px - jpg ",
           ASPECT_RATIO: 1920 / 564,
           ERROR_MESSAGE: {
             ASPECT_RATIO: "The dimension of image must be 1920 x 564",
             FILE_TYPE:
               "Please select a valid jpg/jpeg format file for upload.",
           },
         },
         VIDEO_COVER_IMAGE: {
           FILE_TYPES: ".jpg, .jpeg",
           ID: "VIDEO_POSTER",
           META: "1920x1080px - jpg ",
           ASPECT_RATIO: 16 / 9,
           ERROR_MESSAGE: {
             ASPECT_RATIO: "The dimension of image must be 1920 x 1080",
             FILE_TYPE:
               "Please select a valid jpg/jpeg format file for upload.",
           },
         },
         EVENT_COVER_IMAGE: {
           FILE_TYPES: ".jpg, .jpeg",
           ID: "EVENT_COVER_IMAGE",
           META: "1920x1080px - jpg ",
           ASPECT_RATIO: 16 / 9,
           ERROR_MESSAGE: {
             ASPECT_RATIO: "The dimension of image must be 1920 x 1080",
             FILE_TYPE:
               "Please select a valid jpg/jpeg format file for upload.",
           },
         },
         ADMIN_CONTENT_UPLOAD: {
           FILE_TYPES: ".json",
           ID: "ADMIN_CONTENT_UPLOAD",
           META: "",
           ERROR_MESSAGE: "",
         },
         SUBSCRIPTION_COVER_IMAGE: {
           FILE_TYPES: ".jpg, .jpeg",
           ID: "SUBSCRIPTION_COVER_IMAGE",
           META: "(1920x564)",
           ASPECT_RATIO: 1920 / 564,
           ERROR_MESSAGE: {
             ASPECT_RATIO: "The dimension of image must be 1920 x 564",
             FILE_TYPE:
               "Please select a valid jpg/jpeg format file for upload.",
           },
         },
         LOGO_IMAGE: {
           FILE_TYPES: ".png",
           WIDTH: 1260,
           HEIGHT: 192,
           ID: "LOGO_IMAGE",
           META: "",
           ASPECT_RATIO: 1260 / 192,
           ERROR_MESSAGE: {
             ASPECT_RATIO: "The dimension of image must be 1260 x 192",
             FILE_TYPE: "Please select a valid png format file for upload.",
           },
         },
         FAVICON_IMAGE: {
           FILE_TYPES: ".ico",
           WIDTH: 16,
           HEIGHT: 16,
           ID: "faviconUrl",
           META: "",
           ASPECT_RATIO: 16 / 16,
           ERROR_MESSAGE: {
             ASPECT_RATIO: "The dimension of image must be 16 x 16",
             FILE_TYPE: "Please select a valid ico format file for upload.",
           },
         },
         HOME_IMAGE: {
           FILE_TYPES: ".jpg, .jpeg",
           WIDTH: 1920,
           HEIGHT: 1080,
           ID: "HOME_IMAGE",
           META: "",
           ASPECT_RATIO: 1920 / 1080,
           ERROR_MESSAGE: {
             ASPECT_RATIO: "The dimension of image must be 1920 x 1080",
             FILE_TYPE: "Please select a valid jpg format file for upload.",
           },
         },
         LIGHT_LOGO_IMAGE: {
           FILE_TYPES: ".png",
           WIDTH: 654,
           HEIGHT: 234,
           ID: "lightLogo",
           META: "",
           ASPECT_RATIO: 654 / 234,
           ERROR_MESSAGE: {
             ASPECT_RATIO: "The dimension of image must be 654 x 234",
             FILE_TYPE: "Please select a valid png format file for upload.",
           },
         },
         DARK_LOGO_IMAGE: {
           FILE_TYPES: ".png",
           WIDTH: 654,
           HEIGHT: 234,
           ID: "darkLogo",
           META: "",
           ASPECT_RATIO: 654 / 234,
           ERROR_MESSAGE: {
             ASPECT_RATIO: "The dimension of image must be 654 x 234",
             FILE_TYPE: "Please select a valid png format file for upload.",
           },
         },
         LIGHT_SPLASH_IMAGE: {
           FILE_TYPES: ".png",
           WIDTH: 900,
           HEIGHT: 900,
           ID: "lightSplashIcon",
           META: "",
           ASPECT_RATIO: 16 / 9,
           ERROR_MESSAGE: {
             ASPECT_RATIO: "The dimension of image must be 900 x 900",
             FILE_TYPE: "Please select a valid png format file for upload.",
           },
         },
         DARK_SPLASH_IMAGE: {
           FILE_TYPES: ".png",
           WIDTH: 900,
           HEIGHT: 900,
           ID: "darkSplashIcon",
           META: "",
           ASPECT_RATIO: 16 / 9,
           ERROR_MESSAGE: {
             ASPECT_RATIO: "The dimension of image must be 900 x 900",
             FILE_TYPE: "Please select a valid png format file for upload.",
           },
         },
         APP_ICON: {
           FILE_TYPES: ".png",
           WIDTH: 1024,
           HEIGHT: 1024,
           ID: "lightAppIcon",
           META: "",
           ASPECT_RATIO: 16 / 9,
           ERROR_MESSAGE: {
             ASPECT_RATIO: "The dimension of image must be 1024 x 1024",
             FILE_TYPE: "Please select a valid png format file for upload.",
           },
         },
       };
export const TermsUploadMatadata = {
  TERMS_FILE: {
    FILE_TYPES: ".html",
    ID: "TERMS_FILE",
    ASPECT_RATIO: 16 / 9,
    META: "html file onlt",
    ERROR_MESSAGE: "Upload a file .html only.",
  },
};
export const titleUploadMatadata = {
  TITLE_VIDEO_LISTING: {
    WIDTH: 160,
    HEIGHT: 160,
    FILE_TYPES: ".mp4, .mp3, .aac,.m4a",
    ID: "TITLE_VIDEO",
    ASPECT_RATIO: 16 / 9,
    META: "160x160px - jpg or png",
    ERROR_FILE_NAME_EXCEED_MESSAGE: "File name should not contain above 255 characters",
    ERROR_FILE_NAME_MESSAGE: "File name should not contain special characters",
    ERROR_FILES_NAME_MESSAGE: "Cannot upload more than 5 files.",
    ERROR_FILES_TYPE_MESSAGE: "Allowed only mp4 type.",
    ERROR_MESSAGE:
      "Upload a video that has an aspect ratio\nof 16:9, eg 1920 x 1080.",
  },
  TITLE_VIDEO: {
    WIDTH: 160,
    HEIGHT: 160,
    FILE_TYPES: ".mp4, .mp3, .aac,.m4a",
    ID: "TITLE_VIDEO",
    ASPECT_RATIO: 16 / 9,
    META: "160x160px - jpg or png",
    ERROR_FILE_NAME_EXCEED_MESSAGE: "File name should not contain above 255 characters",
    ERROR_FILE_NAME_MESSAGE: "File name should not contain special characters",
    ERROR_FILES_NAME_MESSAGE: "Cannot upload more than 5 files.",
    ERROR_FILES_TYPE_MESSAGE: "Please select a valid mp4/mp3/aac/m4a format file for upload.",
    ERROR_MESSAGE:
      "Upload a video that has an aspect ratio\nof 16:9, eg 1920 x 1080.",
  },
  TRAILER_VIDEO: {
    WIDTH: 160,
    HEIGHT: 160,
    FILE_TYPES: ".mp4, mp3,.aac,.m4a",
    ID: "TRAILER_VIDEO",
    ASPECT_RATIO: 16 / 9,
    META: "160x160px - jpg or png",
    ERROR_MESSAGE:
      "Upload a video that has an aspect ratio\nof 16:9, eg 1920 x 1080.",
  },
  COVER_PHOTO: {
    WIDTH: 1920,
    HEIGHT: 1080,
    FILE_TYPES: ".jpg, .jpeg, .png",
    ID: "COVER_PHOTO",
    META: "",
  },
  TITLE_ONLY_VIDEO: {
    WIDTH: 160,
    HEIGHT: 160,
    FILE_TYPES: ".mp4",
    ID: "TITLE_VIDEO",
    ASPECT_RATIO: 16 / 9,
    META: "160x160px - jpg or png",
    ERROR_FILE_NAME_EXCEED_MESSAGE: "File name should not contain above 255 characters",
    ERROR_FILE_NAME_MESSAGE: "File name should not contain special characters",
    ERROR_FILES_NAME_MESSAGE: "Cannot upload more than 5 files.",
    ERROR_FILES_TYPE_MESSAGE: "Please select a valid mp4 format file for upload.",
    ERROR_MESSAGE:
      "Upload a video that has an aspect ratio\nof 16:9, eg 1920 x 1080.",
  },
  TITLE_AUDIO: {
    FILE_TYPES: ".mp3, .aac,.m4a",
    ID: "TITLE_AUDIO",
    META: "",
    ERROR_FILE_NAME_EXCEED_MESSAGE: "File name should not contain above 255 characters",
    ERROR_FILE_NAME_MESSAGE: "File name should not contain special characters",
    ERROR_FILES_NAME_MESSAGE: "Cannot upload more than 5 files.",
    ERROR_FILES_TYPE_MESSAGE: "Please select a valid mp3/aac/m4a format file for upload.",
  },
  TRAILER_AUDIO: {
    FILE_TYPES: ".mp3, .aac,.m4a",
    ID: "TRAILER_AUDIO",
    META: "",
  },
};

export const userDataUploadMetaData = {
  USER_UPLOAD: {
    FILE_TYPES: ".csv",
    ID: "USER_CONTENT_UPLOAD",
    META: "",
    ERROR_MESSAGE: "",
  }
}
